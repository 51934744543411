.navigation {
  height: 100vh;
  padding: 20px 16px;
  box-sizing: border-box;
  color: #1b1b26;
  position: relative;
}

.logo-link {
  margin: 0px 0px 10px 16px;
}

.nav-item-category {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: #1b1b26;
  opacity: 0.6;
  margin: 16px 0px 10px 20px;
}

.nav-item,
.nav-item-single-icon {
  width: 220px;
  height: 34px;
  font-size: 14px;
  align-items: center;
  padding-left: 18px;
  color: #1b1b26;
  text-decoration: none;
  border-radius: 8px;
  margin-bottom: 4px;
  opacity: 0.9;
  overflow: hidden;
  word-wrap: none;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
}

.nav-item-single-icon {
  width: unset;
  height: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.nav-item-collapsed {
  width: 60px;
  height: 35px;
}

.nav-item svg {
  margin-right: 10px;
  flex-shrink: 0;
  width: 20px;
}

.nav-item-single-icon:hover,
.nav-item.active,
.nav-item:hover {
  background: rgba(150, 150, 197, 0.2);
  opacity: 1;
}

.nav-item.active::before {
  content: '';
  display: inline;
  width: 3px;
  height: 20px;
  border-radius: 3px;
  background-color: #6123ff;
  position: absolute;
  top: 8px;
  left: 0;
}

.nav-separator {
  margin-left: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  opacity: 0.9;
  font-size: 16px;
  cursor: pointer;
  transition: 200ms;
}

.nav-separator:hover {
  opacity: 1;
}

.nav-separator-open .nav-open-ico {
  transform: rotate(180deg);
}

.nav-user {
  align-items: center;
  padding-left: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 300ms;
  flex: 1;
}

.nav-user:hover {
  opacity: 0.8;
}

.nav-user .email {
  font-size: 13px;
  opacity: 0.6;
}

.nav-user .name {
  /* margin-bottom: 6px; */
  font-size: 14px;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  overflow: hidden;
  margin-right: 8px;
  background-color: #a2a2a8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
}

.avatar img {
  width: 100%;
}

.collapse-menu {
  position: absolute;
  top: 26px;
  cursor: pointer;
  background: #fff;
  padding: 6px 7px;
  border-radius: 10px;
  box-shadow: 1px 3px 7px #00000021;
}

.collapse-menu-icon {
  transition: 0.4s;
}

.collapse-menu-icon-collapsed {
  transform: rotate(180deg);
}
