.cards-v3 {
  height: calc(100vh);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #000000a6;
}

.cards-carousel {
  position: relative;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards-v3-item {
  width: 556px;
  height: 700px;
  background: radial-gradient(134.58% 176.27% at 95.67% 7.85%, #0b1d63 0%, #0c0726 61.98%, #09051e 86.98%);
  border-radius: 26px;
  position: absolute;
  color: #fff;
  padding: 80px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 500ms;
}

.cards-v3-item h2 {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  text-align: center;
  margin-bottom: 30px;
}

.cards-v3-item p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 30px;
}

/* replace cards styles */

.cards-v3-item {
  /* width: 502px; */
  /* height: 646px; */
  background: radial-gradient(134.58% 176.27% at 95.67% 7.85%, #0b1d63 0%, #0c0726 61.98%, #09051e 86.98%);
  border-radius: 26px;
  position: absolute;
  color: #fff;
  padding: 50px;
  overflow: hidden;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1000ms;
}

.cards-v3-item h2 {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
}

.cards-v3-item h3 {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  text-align: center;
  margin-bottom: 16px;
}

.cards-v3-item p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 14px;
}

/* replace cards styles end */

.answer-option {
  position: relative;
  padding: 15px 25px;
  width: 100%;
  background-color: #322171;
  border-radius: 10px;
  margin-bottom: 16px;
  cursor: pointer;
}

.answer-option:hover {
  background-color: #402b8b;
}

.answer-option-marker {
  height: 100%;
  width: 8px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px 0px 0px 8px;
}

.answer-option-wrong {
  background-color: #ff000040;
}

.answer-option-correct {
  background-color: #1ed80040;
}

.answer-option-wrong .answer-option-marker {
  background-color: #ff0000;
}

.answer-option-correct .answer-option-marker {
  background-color: #1ed800;
}

.explanation-wrong {
  background: #a81919;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 27px 21px;
  color: #fff;
  position: absolute;
  top: -80px;
  right: 0;
  width: 220px;
}

.explanation-wrong span {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  /* or 14px */

  color: #c4c0dc;
}
