.drawer-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 110%;
  /* identical to box height, or 39px */

  /* Black/100 */

  color: #1b1b26;
  margin-bottom: 24px;
}

.drawer-subtitle {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  color: #1b1b26;
  margin-bottom: 20px;
  margin-top: 20px;
}

div.rs-picker-daterange-menu {
  z-index: 1300;
}

div.rs-picker-toggle-wrapper {
  max-width: 570px;
  height: 60px;
}

div.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-bottom: 10px;
  padding-top: 10px;
}

input.rs-picker-toggle-textbox {
  /* padding-right: 10px;
  padding-left: 32px; */
}

div.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #6123ff;
}

div.rs-picker-menu div.rs-calendar div.rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  color: #6123ff;
  background-color: #f7f4ff;
}

div.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #fff;
  background-color: #6123ff;
}

button.rs-btn-primary {
  background-color: #6123ff;
  padding: 8px 30px;
}

button.rs-btn-primary:hover {
  background-color: #7a46fc;
}

div.rs-calendar-table-cell-in-range:before {
  background-color: #f7f4ff;
}

div.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
div.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #1b1b26;
}

.pointer {
  cursor: pointer;
}

.collapsible-topic-title:hover {
  opacity: 0.8;
}
