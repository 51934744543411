.copyright {
  margin-bottom: 32px;
}

.copyright p {
  line-height: 24px;
  color: #101828;
  opacity: 0.5;
}

.lang-select {
  /* cursor: pointer; */
}

.lang-select svg {
  transition: 0.5s;
}

.lang-select-open svg {
  transform: rotate(180deg);
}
