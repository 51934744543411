.user-info {
  background-color: #f8f8fb;
  border-left: 1px solid #e7e7ee;
  width: 648px;
  height: 100vh;
  box-sizing: border-box;
  padding: 32px 32px 0px 32px;
  overflow: auto;
  flex-shrink: 0;
}

.user-details {
  padding: 32px;
  flex: 1;
  width: 380px;
}

.user-image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 32px;
  background-color: #b7b7c0;
  color: #fff;
  font-size: 28px;
  flex-shrink: 0;
}

.user-image img {
  height: 100%;
}

.user-details-group {
  height: 32px;
}

p.user-details-label {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  /* Black/60 */
  color: #76767f;
  width: 120px;
}

p.user-details-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Black/100 */
  color: #1b1b26;
}

.user-view-user-name {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  /* or 29px */

  /* Black/100 */
  width: 200px;

  color: #1b1b26;
}

.user-details-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  color: #1b1b26;
  margin-top: 20px;
  margin-bottom: 8px;
}

.user-details-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #1b1b26;
  margin-bottom: 32px;
}

.user-details-group-title {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 8px;
  color: #76767f;
}

.user-details-group-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.user-preview {
  align-items: center;
  padding: 32px;
}

.user-preview img {
  width: 481px;
}

.user-info-paper-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #76767f;
}

.user-info-paper-big-num {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 120%;

  color: #1b1b26;
}

.user-info-paper-mid-num {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #1b1b26;
  margin-left: 10px;
  margin-right: 8px;
}

.user-info-progress-stars-bar {
  width: 180px;
  height: 8px;
  border-radius: 8px;
  background-color: #e7e7ee;
  position: relative;
}

.user-info-progress-stars-filled {
  height: 8px;
  border-radius: 8px;
  background-color: #fac630;
}

.user-info-progress-stars-indicator {
  position: absolute;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(250, 198, 48, 0.2), rgba(250, 198, 48, 0.2)), #ffffff;
  border: 6px solid #ffffff;
  border-radius: 48px;
  top: -25px;
  z-index: 1;
}

.user-info-progress-stars-text {
  position: absolute;
  top: -40px;
  font-weight: 600;
  font-size: 14px;
}

.activity-history {
  margin-top: 48px;
}

.activity-history-datetime {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  text-align: right;

  color: #76767f;
  white-space: nowrap;
}

.activity-history-activity-name {
  margin-left: 16px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;

  max-width: 303px;
}

.activity-history-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */

  /* Black/100 */

  color: #1b1b26;
}
