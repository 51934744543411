@font-face {
  font-family: 'Satoshi';
  src:
    local('Satoshi'),
    url('./fonts/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Satoshi';
  src:
    local('Satoshi'),
    url('./fonts/Satoshi-Bold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Satoshi';
  src:
    local('Satoshi'),
    url('./fonts/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./fonts/PlusJakartaSans-Variable.ttf') format('truetype');
  font-weight: 200 300 400 500 600 700 800;
  font-display: swap;
  font-style: normal;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: 'Plus Jakarta Sans', sans-serif;
  box-sizing: border-box;
}

b {
  font-weight: 900;
}

i {
  font-style: italic;
}

strong {
  font-weight: 600;
}

h1 {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 120%;
  color: #1b1b26;
  margin-bottom: 24px;
}

@media screen and (max-width: 1440px) {
  h1 {
    font-size: 32px;
  }
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* overwrite editor styles */
div.rsw-editor {
  overflow: unset;
}

.rsw-dd {
  margin-right: 10px;
  border: 0;
  padding: 5px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
}

.rsw-dd option {
  font-size: 16px;
}

.rsw-btn {
  color: #545454;
  border-radius: 5px;
  margin: 0px 4px 0px 0px;
}

.rsw-toolbar {
  background-color: #f8f8fb;
  padding: 10px;
  position: relative;
}

.rsw-ce {
  height: 300px;
  overflow: auto;
}

.rsw-ce ul {
  list-style: disc;
  padding-left: 2em;
}

.rsw-ce ol {
  list-style: decimal;
  padding-left: 2em;
}

.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}
