.threat-list-item {
  height: 72px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.threat-list-item:hover {
  background-color: #f7f4ff82;
}

.threat-list-item-selected {
  background-color: #f7f4ff;
}

.threat-list-item-icon {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background: #ffffff;
  border: 1px dashed #d0d0d8;
  margin: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.threat-list-item-icon-marked {
  background-color: #f8f8fb;
  border: 0;
}

.threat-item-icon-marked {
  align-items: center;
  background-color: #fff;
  border: 2px solid #dfd3ff;
}

.threat-list-item-selected .threat-list-item-icon {
  border: 1px dashed #6123ff;
}

.threat-list-item-selected .threat-list-item-icon-marked {
  border: 2px solid #dfd3ff;
  background-color: #fff;
}

.threat-list-item-title {
  font-size: 16px;
  line-height: 24px;
  color: #1b1b26;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.threat-list-item-marked_as {
  font-size: 14px;
  line-height: 22px;
  color: #e3a202;
}

.marked_as_fraudlent {
  color: #b40020;
}

.marked_as_safe {
  color: #00bc62;
}

.threat-list-item-date {
  margin-right: 12px;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  color: #76767f;
}
