.App {
  text-align: center;
}

#root {
  height: 100vh;
}

.app-layout {
  background: #f3f3f7;
  display: flex;
}

.app-content {
  background-color: #fff;
  flex: 1;
  overflow: hidden;
  height: 100vh;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes myAnimation {
  0% {
    opacity: 1;
    transform: rotate(0deg);
  }

  100% {
    display: none;
    opacity: 0;
    transform: rotate(180deg);
  }
}

.onboarding-button-closing {
  animation-name: myAnimation;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.onboarding-button {
  width: 100px;
  height: 100px;
  border-radius: 1000px;
  background: #82effe;
  position: fixed;
  bottom: 48px;
  right: 68px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 9px 20px 3px #00000025;
}

.title-24 {
  font-family: 'Satoshi';
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
}

.title-20 {
  font-family: 'Satoshi';
  font-size: 20px;
  font-weight: 900;
  line-height: 29px;
}

.title-20-500-grey {
  font-family: 'Satoshi';
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  color: #76767f;
}

.mr24 {
  margin-right: 24px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb24 {
  margin-bottom: 24px;
}

.font600 {
  font-weight: 600;
}

.text {
  line-height: 24px;
}

.vertical-line {
  width: 1px;
  /* height: 100%; */
  flex: 1;
  background: linear-gradient(180deg, #e7e7ee 0%, rgba(231, 231, 238, 0) 100%);
  margin-top: 8px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.row-center {
  align-items: center;
}

.row-start {
  align-items: flex-start;
}

.spacer {
  display: flex;
  flex: 1;
}

.white-box {
  padding: 24px 32px;
  border-radius: 10px;
  margin-bottom: 16px;
  background-color: #fff;
}

.white-box h2 {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #76767f;
  margin-bottom: 16px;
}

.highlight-text {
  background-color: #dfd3ff;
  color: #6123ff;
}

.sato24 {
  font-family: Satoshi;
  font-size: 24px;
}

.sato42-500 {
  font-family: Satoshi;
  font-size: 42px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0px;
  text-align: left;
}

.sato24-900 {
  font-family: Satoshi;
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
}

.mb20 {
  margin-bottom: 20px;
}

.text-shrink-1 {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  flex-shrink: 0;
}

.text-shrink-2 {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  flex-shrink: 0;
}

.text-shrink-6 {
  display: -webkit-box;
  line-clamp: 6;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  flex-shrink: 0;
}

.pointer {
  cursor: pointer;
}

.page-title {
  font-size: 24px;
  margin: 0;
}

.page-heading {
  padding: 21px 16px;
  margin-bottom: 36px;
  border-bottom: 2px solid #e7e8ea;
}

button.export-btn:hover {
  border-color: #6d37f7;
}

.rsw-ce.rsw-html {
  resize: none;
}

.large-screen-mobile {
  background-image: url('../public/images/bg.jpg');
  position: fixed;
  background-color: #ffffff;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  padding: 30px 30px;
}

.settings-section .rs-picker-toggle-wrapper {
  height: 35px;
}

.rs-picker-popup .rs-picker-toolbar {
  align-items: center !important;
}

.rs-picker-popup .rs-btn-primary {
  background-color: #0a5dc2;
}

.rs-picker-popup .rs-btn-primary:hover {
  background-color: #0a5dc2;
}
