.container-1030 {
  width: 1030px;
  margin: auto;
}

.container-1089 {
  width: 1089px;
  margin: auto;
}

.container-800 {
  width: 800px;
  margin: auto;
}

@media (max-width: 1276px) {
  .container-1089 {
    width: 100%;
    min-width: 900px;
    margin: auto;
  }
}

.green-text {
  color: #00bc62;
  font: inherit;
}

.red-text {
  color: #ed4563;
  font: inherit;
}

.overal-indicator {
  position: relative;
  margin-top: 30px;
}

.dashboard-section {
  margin-bottom: 36px;
}

.dashboard-section-block {
  border-radius: 20px;
  border: 1px solid #1c1c270d;
  background-color: #ffffff;
  padding: 25px 32px;
}

.dashboard-section-block-subtitle {
  color: #1b1b2680;
  font-weight: 500;
}

.sato-32 {
  font-family: 'Satoshi';
  font-size: 32px;
  font-weight: 500;
}

.sato-24 {
  font-family: 'Satoshi';
  font-size: 24px;
  font-weight: 500;
}

.sato-36 {
  font-family: 'Satoshi';
  font-size: 36px;
  font-weight: 500;
}

.big-num-72 {
  font-family: 'Satoshi';
  font-size: 72px;
  font-weight: 500;
}

.big-num-36 {
  font-family: 'Satoshi';
  font-size: 36px;
  font-weight: 500;
}

.big-num-28 {
  font-family: 'Satoshi';
  font-size: 28px;
  font-weight: 500;
}

.big-num-24 {
  font-family: 'Satoshi';
  font-size: 24px;
  font-weight: 500;
}

.dashboard-section-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 21.76px;
  text-align: left;
  color: #1b1b26;
  opacity: 0.5;

  margin-bottom: 14px;
  margin-left: 16px;
}

.dashboard-section-block-title {
  font-family: 'Satoshi';
  font-size: 20px;
  font-weight: 500;
}

.dashboard-section-lebel {
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.3px;
  background: linear-gradient(264.92deg, rgba(0, 186, 151, 0.1) 4.82%, rgba(0, 188, 98, 0.1) 73.44%);
  padding: 3px 18px 5px 18px;
  border-radius: 8px;
}

.dashboard-section-lebel.dashboard-section-lebel-2 {
  background: #fef8e1;
}

.dashboard-section-lebel.dashboard-section-lebel-3 {
  background: #fff0f0;
}

.dashboard-section-lebel-1 p {
  background: linear-gradient(264.92deg, #00ba97 4.82%, #00bc62 73.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.benchmark-sign-container:hover .benchmark-sign {
  opacity: 1 !important;
}

.dashboard-section-lebel-2 p {
  color: #f4c508;
}

.dashboard-section-lebel-3 p {
  color: #ed4563;
}

.see-details {
  cursor: pointer;
}

.see-details p {
  color: #6123ff;
  font-weight: 600;
}

.see-details svg {
  margin-left: 6px;
  transition: 500ms;
}

.see-details:hover svg {
  margin-left: 8px;
}

.secondary-text-14 {
  font-size: 14px;
  font-weight: 500;
  color: #1b1b26;
  opacity: 0.5;
  font-weight: 500;
}

.secondary-text-16 {
  font-size: 16px;
  font-weight: 500;
  color: #1b1b26;
  opacity: 0.5;
  font-weight: 500;
}

.tab-small {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 6px;
  background-color: #f7f4ff;
  color: #6123ff;
  font-weight: 600;
  padding: 3px 20px;
  border-radius: 50px;
  cursor: pointer;
}

.tab-small-selected {
  background-color: #fff;
  color: #1b1b26;
  border: 1px solid #1c1c270d;
  padding: 2px 20px;
}

.tab-small:hover {
  opacity: 0.8;
}

.risky-element {
  margin-right: 20px;
  margin-bottom: 20px;
}

.risky-element:nth-child(3n) {
  margin-right: 0;
}

.period-picker:hover {
  opacity: 0.8;
}

.period-picker-mode {
  padding: 2px;
  background-color: #f8f8fb;
  border-radius: 8px;
  margin-top: 24px;
}

.period-picker-mode-item {
  border-radius: 6px;
  flex: 1;
  text-align: center;
  padding: 7px 0px;
  color: #1b1b2680;
  font-weight: 600;
  cursor: pointer;
  transition: 300ms;
}

.period-picker-mode-item.active {
  background-color: #fff;
  color: #1b1b26;
  cursor: auto;
}

.MuiPickersCalendarHeader-labelContainer {
  /* opacity: 1; */
  cursor: auto !important;
}

.MuiPickersCalendarHeader-labelContainer button {
  display: none;
  cursor: auto;
}

.custom-year-switcher {
  width: 100%;
  position: absolute;
  top: 143px;
  right: 0px;
  padding: 0px 32px 0px 42px;
  background: #ffffff;
  z-index: 2;
}
