.settings-container {
  height: calc(100vh - 155px);
  overflow-y: auto;
}

.settings-container h2 {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;

  color: #1b1b26;
  margin-bottom: 8px;
}

.settings-section {
  margin-bottom: 64px;
}

.mb32 {
  margin-bottom: 32px;
}

.settings-nav-item {
  font-size: 14px;
  font-weight: 600;
  color: #76767f;
  text-decoration: none;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  margin-bottom: 4px;
}

.settings-nav-item.active,
.settings-nav-item:hover {
  color: #1b1b26;
  background-color: #f4f5f9;
}

@media screen and (max-width: 1440px) {
  .settings-container h2 {
    font-size: 20px;
  }
}

.admins-list-item {
  cursor: pointer;
  padding: 20px 10px;
  border-bottom: 1px solid #f5f5f9;
  transition: 200ms;
}

.admins-list-item:hover {
  background-color: #f4f5f9;
}
