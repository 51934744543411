.auth-link-page {
  background-image: url('../../../public/images/bg.jpg');
  min-height: 100vh;
  overflow: auto;
  align-items: center;
}

.login-form-container {
  background-color: #fff;
  border-radius: 24px;
  padding: 36px;
  /* width: 569px; */
  margin-bottom: 32px;
}

.logo {
  margin-top: 60px;
  margin-bottom: 40px;
}

.login-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 110%;
  color: #101828;
}

.login-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6123ff;
  text-decoration: none;
  margin-top: 16px;
  margin-bottom: 16px;
}

.reset-pass-description {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1b1b26;
  margin-top: 16px;
  margin-bottom: 24px;
}

.back-link {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6123ff;
  cursor: pointer;
}

.back-link:hover {
  opacity: 0.8;
}

.back-link svg {
  margin-right: 16px;
}
