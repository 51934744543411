.registration-page {
  background-image: url('../../../public/images/bg.jpg');
  min-height: 100vh;
  overflow: hidden;
  align-items: center;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.registration-form-container {
  background-color: #fff;
  border-radius: 24px;
  padding: 36px;
  width: 569px;
  box-shadow: 4px 4px 55px #938aa31c;
  overflow: hidden;
  margin-bottom: 40px;
  transition: 300ms;
  align-items: flex-start;
}

.logo {
  margin-top: 60px;
  margin-bottom: 40px;
}

.registration-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 110%;
  color: #101828;
}

.registration-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6123ff;
  text-decoration: none;
  margin-top: 16px;
  margin-bottom: 16px;
}

.error-text {
  color: #b40020;
  margin-left: 16px;
  margin-top: 8px;
}

.column-center {
  align-items: center;
}

.column-start {
  align-items: flex-start;
}

.slider-frame {
  width: 100%;
  flex-shrink: 0;
  transition: 300ms;
  margin-right: 38px;
}

.ts-cs {
  margin: 24px 0 0 0;
}

.data-proc-agree {
  margin: 24px 0;
}

@media (max-width: 660px) {
  .registration-form-container {
    padding: 30px;
    width: calc(100vw - 60px);
    overflow: hidden;
    margin-bottom: 30px;
  }

  .registration-title {
    font-size: 25px;
    line-height: 110%;
  }

  .ts-cs {
    margin-bottom: 0;
  }

  .reg-btn {
    height: 45px !important;
  }
}
