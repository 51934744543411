.groups-list-container {
  height: calc(100vh - 280px);
  overflow: auto;
}

.groups-list-heading {
  border-bottom: 1px solid #e7e7ee;
  padding: 12px 0px;
}

.groups-list-item {
  padding: 20px 0px;
  border-bottom: 1px solid #e7e7ee;
}

.progress-hatched {
  position: relative;
  flex: 1;
  height: 28px;
  border-radius: 8px;
  overflow: hidden;
  background: url('/public/images/progress-pattern.png');
  position: relative;
}

.progress-hatched-inner {
  border-radius: 8px;
  padding: 8px;
  background-color: #c7eff4;
  color: #1b1b26;
}

.progress-hatched-inner svg {
  margin-right: 8px;
}

.groups-list-table-sorter {
  cursor: pointer;
  font-size: 13px;
  color: #76767f;
  font-weight: 600;
}

.groups-list-table-sorter:hover {
  color: #6123ffaa;
}

.groups-list-table-sorter-disabled {
  cursor: auto !important;
}

.groups-list-table-sorter-disabled:hover {
  color: #76767f !important;
}

@media (max-width: 1440) {
  .groups-list-container {
    height: calc(100vh - 230px);
    overflow: auto;
  }
}
