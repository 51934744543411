.select-workflow {
  background-color: #c0a7ff1a;
  color: #6123ff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
  border-radius: 12px;
  border: 1px dashed #c0a7ff;
}

.select-workflow:hover {
  background-color: #c0a7ff2a;
}
