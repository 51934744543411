.table-container {
  border: 1px solid #e7e7ee;
  border-radius: 22px;
  overflow: hidden;
  height: calc(100vh - 115px);
  position: relative;
}

.table-container-header {
  background-color: #f8f8fb;
  height: 82px;
}

.table-container-header-active {
  background-color: #e7e7ee;
}

.table {
  height: calc(100vh - 270px);
  overflow-y: scroll;
  position: relative;
  align-items: flex-start;
}

.table-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff50;
  z-index: 200;
}

.table-header-cell {
  height: 58px;
  align-items: center;
  display: flex;
  background-color: #f8f8fb;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #76767f;
}

.table-header-cell-btn {
  cursor: pointer;
  height: 32px;
  padding: 0px 12px;
  border-radius: 6px;
  transition: 0.4s;
  position: relative;
  overflow: hidden;
}

.table-header-cell-opions {
  /* height: 250px; */
  min-width: 190px;
  position: absolute;
  top: 34px;
  left: 0px;
  background: #ffffff;
  box-shadow: 0px 3px 23px rgba(146, 162, 187, 0.3);
  border-radius: 8px;
  z-index: 2;
  padding: 10px 0px;
}

.table-header-cell-opion {
  font-weight: 600;
  font-size: 14px;
  padding: 8px 16px;
  justify-content: flex-start;
  cursor: pointer;
}

.table-header-cell-opion:hover {
  background-color: #e7e7ee;
}

.table-header-cell-chip {
  background: #e7e7ee;
  border-radius: 8px;
  padding: 8px;
  margin-right: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #1b1b26;
}

.table-header-cell-filercount {
  background-color: rgba(97, 35, 255, 0.1);
  height: 100%;
  padding: 0px 10px;
  margin-right: -12px;
}

.table-header-cell-btn span {
  margin-right: 10px;
  white-space: nowrap;
}

.table-header-cell-btn svg {
  transition: 0.4s;
}

.table-header-cell-btn-open .open-ico {
  transform: rotate(180deg);
}

.table-header-cell-btn:hover,
.table-header-cell-btn-open {
  background-color: #e7e7ee;
  color: #1b1b26;
}

.table-header-cell-btn-active {
  background-color: rgba(97, 35, 255, 0.05);
  color: #1b1b26;
}

.table-cell {
  height: 78px;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #f1f1f5;
  overflow: hidden;
  font-size: 14px;
  padding: 0px 12px;
}

.table-cell-selected {
  background-color: #f8f8fb;
}

.popup-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
}

.poagintion-popup {
  background: #ffffff;
  box-shadow: 0px 3px 23px rgba(146, 162, 187, 0.3);
  border-radius: 8px;
  z-index: 2;
  position: absolute;
  bottom: 20px;
  left: 0;
  max-height: 200px;
  overflow: auto;
  text-align: center;
  padding: 10px 0px;
  width: 62px;
}

.poagintion-popup-el {
  padding: 6px 15px;
  color: #76767f;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.poagintion-popup-el-active {
  background-color: #e7e7ee;
  color: #1b1b26;
}

.poagintion-popup-el:hover {
  background-color: #f8f8fb;
  color: #1b1b26;
}

@media (max-width: 1440px) {
  .table-container {
    height: calc(100vh - 105px);
  }

  .table {
    height: calc(100vh - 260px);
  }
}
