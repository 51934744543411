.container-1035 {
  width: 1035px;
  margin: 0 auto;
  min-height: calc(100vh + 1px);
}

.create-campaign-editable {
  transition: 300ms;
  padding: 4px;
}

.create-campaign-editable:hover {
  background-color: #ffffff80;
}

.create-campaign-actions {
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.create-campaign-section {
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  width: 100%;
  margin-bottom: 20px;
}

.create-campaign-section-title {
  font-size: 22px;
  font-weight: 900;
  margin-bottom: 20px;
  font-family: 'Satoshi';
}

.create-campaign-dialog-title {
  font-size: 22px;
  font-weight: 900;
  font-family: 'Satoshi';
}

.templates-lib-dialog-container {
  height: calc(-200px + 95vh);
  overflow: auto;
  background: #f3f3f738;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #f3f3f7;
}

.templates-lib-dialog-container .templates-grid-item-img {
  height: 160px;
  background: #fff;
}

.templates-lib-dialog {
  width: 95vw;
  height: 95vw;
  padding: 24px;
  max-width: 1368px;
}

.templates-lib-dialog .templates-grid {
  width: 100%;
}

.create-template-container {
  width: 90vw;
  /* height: 90vh; */
  max-width: 1360px;
  /* padding-bottom: 20px; */
  /* max-height: 751px; */
}

.create-campaign-section .templates-grid {
  width: 100%;
}
