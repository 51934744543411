.templates-grid {
  flex-wrap: wrap;
  width: 819px;
}

.campaign-view .templates-grid {
  flex-wrap: wrap;
  width: 107%;
  overflow-y: auto;
}

.templates-grid-item {
  width: calc((100% - 48px) / 4);
  margin-bottom: 24px;
  margin-right: 16px;
}

.campaign-view .templates-grid-item {
  width: 162px;
  margin-right: 10px;
}

.templates-grid-item:nth-child(4n) {
  margin-right: 0px;
}

.campaign-view .templates-grid-item:nth-child(2n) {
  margin-right: 0px;
}

.templates-grid-item.active .templates-grid-item-img {
  border: 1px solid #6123ff;
}

.templates-grid-item-img {
  position: relative;
  width: 100%;
  height: 120px;
  border-radius: 14px;
  border: 1px solid #e7e7ee;
  margin-bottom: 12px;
  overflow: hidden;
  padding: 10px 10px;
}

.template-thumbbail:hover {
  opacity: 0.8;
}

.template-thumbbail {
  transform: scale(0.3);
  width: 600px;
  transform-origin: left top;
  cursor: pointer;
}

.templates-grid-item-title:hover {
  opacity: 0.8;
  color: #6123ff;
}

.templates-grid-item-title {
  font-weight: 600;
  color: #000000;
  line-height: 22px;
  margin-bottom: 4px;
  cursor: pointer;
}

.templates-grid-item-domain:hover {
  opacity: 0.8;
}

.templates-grid-item-domain {
  font-size: 13px;
  color: #76767f;
  cursor: pointer;
}
