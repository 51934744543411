.threats-list {
  height: calc(100vh - 175px);
  overflow-y: auto;
}

.threat-details-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  flex-shrink: 0;
}

.reported-by {
  background: #ffffff;
  border-radius: 10px;
  padding: 24px;
}

.reported-by-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #1b1b26;
}

.reported-by-title span {
  cursor: pointer;
  opacity: 0.9;
}

.reported-by-title span:hover {
  color: #401b9b;
}

.reported-by-description {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  /* Black/60 */

  color: #76767f;
}

.reported-by-dateTime {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  text-align: right;

  /* Black/60 */

  color: #76767f;
}

.reported-by-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  /* Black/100 */

  color: #1b1b26;
}

.reported-by-avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 14px;
  background-color: #76767f38;
  flex-shrink: 0;
}

.threat-details-template-preview {
  /* align-items: center; */
  /* justify-content: center; */
  margin-top: 0px;
  background-color: #fff;
  width: 100%;
  overflow-x: auto;
  /* padding: 10px; */
  border-radius: 0px 0px 10px 10px;
  height: calc(100vh - 370px);
  word-break: break-all;
  position: relative;
  padding: 24px;
}

.letteerr {
  max-width: 100%;
  /* max-width: 665px; */
}
