.template-container {
  background-color: #f8f8fb;
  border-right: 1px solid #e7e7ee;
  width: 745px;
  height: 100vh;
}

.template-container-header {
  background-color: #f4f5f9;
  border-bottom: 1px solid #e7e7ee;
  padding: 32px;
  padding-bottom: 24px;
}

.template-details {
  padding: 32px;
  flex: 1;
}

.template-details-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  color: #1b1b26;
  margin-top: 20px;
  margin-bottom: 8px;
}

.template-details-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #1b1b26;
  margin-bottom: 32px;
}

.template-details-group-title {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 8px;
  color: #76767f;
}

.template-details-group {
  align-items: flex-start;
  margin-bottom: 20px;
}

.template-details-group-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.template-preview {
  align-items: center;
  padding: 32px;
  background-color: #fff;
  width: 100%;
  overflow-x: auto;
  align-items: flex-start;
  justify-content: flex-start;
}

.breach-user:hover {
  background-color: #f8f8fb;
  border-radius: 10px;
  padding: 10px;
  margin: -10px 0px 10px -10px;
}
