.import-drop-zone {
  border: 1px dashed #c0a7ff;
  border-radius: 12px;
  margin-bottom: 16px;

  align-items: center;

  padding-top: 56px;
  padding-bottom: 96px;
}

.import-drop-zone-active {
  background: #f7f4ff;
  border: 1px solid #cfbdff;
  border-radius: 12px;
}

.upload-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1b1b26;
  margin-top: 12px;
  margin-bottom: 8px;
}
.upload-link span {
  cursor: pointer;
  color: #6123ff;
  padding: 4px;
  border-radius: 4px;
  transition: 0.5s;
}
.upload-link span:hover {
  color: #6123ff;
  background-color: #6123ff25;
}

.upload-filesize-msg {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #76767f;
}
