.sticky-pagination {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ddd;
  z-index: 32;
}

.regular-pagination {
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ddd;
}
