.tab {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1b1b26;
  display: flex;
  padding: 18px 20px;
  margin-right: 10px;
  cursor: pointer;
}

.tab span {
  width: 24px;
  height: 24px;
  background: #f4f5f9;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-weight: 600;
  font-size: 13px;
}

.tab-active {
  background: #6123ff1a;
  border-radius: 8px 8px 0px 0px;
  color: #6123ff;
  font-weight: 600;
  font-size: 16px;
}

.tab-active span {
  background: #e7e7ee;
  color: #1b1b26;
}

.row.tabs {
  border-bottom: 1px solid #e7e7ee;
}

.table-header-cell-text {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #76767f;
}

.manual-simulation-el {
}

.manual-simulation-item {
  padding: 12px 8px;
  border-bottom: 1px solid #f4f5f9;
  /* border-radius: 10px; */
  position: relative;
  overflow: hidden;
  margin: 8px 0px;
}

.manual-simulation-name {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  color: #1b1b26;
  cursor: pointer;
  transition: 0.3s;
}

.manual-simulation-dascription {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #5f5f68;
}

.manual-simulation-status {
  background-color: #fbf1d9;
  padding: 10px 18px;
  font-size: 14px;
  border-radius: 8px;
  text-transform: capitalize;
}

.manual-simulation-status svg {
  margin-right: 8px;
}

.manual-simulation-status-published {
  background-color: #00bc6226;
}

.manual-simulation-status-new {
  background-color: #7e7e7e26;
}

.manual-simulation-micros_count {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -1px;
  color: #1b1b26;
}

.manual-simulation-planned_date {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1b1b26;
}

.row-actions {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  overflow: hidden;
  cursor: pointer;
  margin-right: 20px;
  transition: 0.4s;
}

.row-actions:hover {
  background-color: #e7e7ee;
}

/* to refactor next */

.groups-list-container {
  height: calc(100vh - 190px);
  overflow: auto;
}

.groups-list-heading {
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  padding: 12px 0px;
}

.groups-list-item {
  padding: 20px 0px;
  border-bottom: 1px solid #e7e7ee;
}

.progress-hatched {
  position: relative;
  flex: 1;
  height: 28px;
  border-radius: 8px;
  overflow: hidden;
  background: url('/public/images/progress-pattern.png');
  position: relative;
}

.package-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.progress-hatched-inner {
  border-radius: 8px;
  padding: 8px;
  background-color: #c7eff4;
  color: #1b1b26;
}

.progress-hatched-inner-green {
  background: linear-gradient(264.92deg, rgba(0, 186, 151, 0.2) 4.82%, rgba(0, 188, 98, 0.2) 73.44%),
    linear-gradient(0deg, #ffffff, #ffffff);
}

.progress-hatched-inner-warning {
  background: #fee8d8;
}

.progress-hatched-inner svg {
  margin-right: 8px;
}

.groups-list-table-sorter {
  cursor: pointer;
  font-size: 13px;
  color: #76767f;
  font-weight: 600;
}

.groups-list-table-sorter:hover {
  color: #6123ffaa;
}

.campaigns-group {
  margin-bottom: 30px;
}

.hov-opacity-80:hover {
  opacity: 0.8;
}
