.select-type {
  cursor: pointer;
  padding: 8px 12px;
  background: #e7e7ee;
  border-radius: 6px;
}

.select-type-threat {
  background-color: unset;
}

.micro-training-items {
  flex-wrap: wrap;
  height: calc(100vh - 120px);
  overflow-y: auto;
}

.micro-training-item {
  width: calc(50% - 16px);
  height: 400px;
  padding: 24px 32px;
  border: 1px solid #f4f5f9;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin: 8px;
}

.micro-training-item-rb {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8px;
  background-color: #e7e7ee;
}

.micro-training-item-rb-green {
  background-color: #ccf2e0;
}

.micro-training-item-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  color: #1b1b26;
}

.micro-drawer-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 110%;
  color: #1b1b26;
}

.module-element {
  background: #f4f5f9;
  border: 1px solid #e7e7ee;
  border-radius: 10px;
  padding: 20px 24px;
  margin-bottom: 8px;
}

.module-element-active {
  background: #fff;
}

.cards-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2000;
}
