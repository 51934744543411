.big-num {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: 100%;

  letter-spacing: 1px;

  background: linear-gradient(264.92deg, #00ba97 4.82%, #00bc62 73.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.green-gradient {
  background: linear-gradient(264.92deg, #00ba97 4.82%, #00bc62 73.44%);
}

.wise-chart {
  width: 100%;
  height: 100%;
}

.wise-chart-bar {
  width: 102px;
}

.wise-chart-bar-success {
  width: 100%;
  background: linear-gradient(264.92deg, #00ba97 4.82%, #00bc62 73.44%);
}

.wise-chart-bar-fail {
  width: 100%;
  background: linear-gradient(264.92deg, rgba(240, 40, 76, 0.2) 4.82%, rgba(240, 40, 143, 0.2) 73.44%), #ffffff;
}

.wise-chart-bar-fail-border {
  width: 100%;
  height: 2px;
  background: linear-gradient(264.92deg, #f0284c 4.82%, #f0288f 73.44%), #cb4d63;
}

.wise-chart-bar-miss-border {
  width: 100%;
  height: 2px;
  background: #d0d0d8;
}

.wise-chart-bar-miss {
  width: 100%;
  background: #f4f5f9;
}

.top-themes-element {
  cursor: pointer;
  padding-right: 40px;
}

.top-themes-element:hover {
  opacity: 0.8;
  color: #401b9b;
}

.employee-name:hover {
  opacity: 0.8;
  color: #401b9b;
}

.top-themes-element span {
  color: #76767f;
}
